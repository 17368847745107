<mat-toolbar role="banner" class="topbar">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    fxHide.gt-md
    fxHide.md
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <!-- App Logo -->
  <div class="header-branding" *ngIf="customerLogo!=''">
    <button
      mat-flat-button
      [style.overflow]="'visible'"
      attr.aria-label="{{ 'TOP_NAV.SUBSCRIPTIONS' | translate}}"
      tabindex="0"
      [class]="'style-' + layoutConf.sidebarStyle"
    >
      <img [src]="customerLogo" alt="app-logo" class="header-logo" />
    </button>
  </div>

  <span fxFlex></span>

  <!--<matx-search-input-over placeholder="Country (e.g. US)" resultPage="/search">
  </matx-search-input-over>-->
  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <!--<button
    mat-icon-button
    matTooltip="{{ 'TOP_NAV.NOTIFICATIONS' | translate}}"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
    attr.aria-label="{{ 'TOP_NAV.NOTIFICATIONS' | translate}}"
    disabled
  >
    <mat-icon>notifications</mat-icon>
    <span class="notification-number mat-bg-warn">3</span>
  </button>-->
  
  <button
    mat-icon-button
    (click)="toggleAppSettings()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
    attr.aria-label="{{ 'TOP_NAV.APP_SETTINGS' | translate}}"
  >
    <mat-icon>person</mat-icon>
  </button>
</mat-toolbar>
