import { Inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GeneralSettings, EmailSettings, TextSmsSettings, CustomerLogoResponse, CustomerLogoRequest, CustomField, Translation } from 'app/shared/models/settings.model';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStoreService } from '../local-store.service';
import { BaseService } from '../base.service';
import { DOCUMENT } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { RequestParamNamesHelper, SearchParam } from 'app/shared/helpers/api-requests.helper';

const BASE_URL_GENERAL = 'CustomerGeneralSettings';
const BASE_URL_LOGO = 'customer-logo';
const BASE_URL_EMAIL = 'settings/CustomerEmailSettings';
const BASE_URL_SMS = 'settings/CustomerSmsSettings';
const BASE_URL_CUSTOM_FIELDS = 'settings_custom_fields';
const BASE_URL_TRANSLATIONS = 'settings_translations';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseService {

  paramHelper = new RequestParamNamesHelper();
  private logoSrc = new BehaviorSubject('');
  logoSrcChanged = this.logoSrc.asObservable();

  constructor(private http: HttpClient,
              private ls: LocalStoreService, @Inject(DOCUMENT) private document: Document) {
    super(document);
  }

  getSettingsGeneral(): Observable<GeneralSettings> {
    
    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_GENERAL)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getProductInfo(): Observable<GeneralSettings> {
    
    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_GENERAL + '/getproductdetail')
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveSettingsGeneral(info: GeneralSettings) {
    
    return this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_GENERAL, info)
        .pipe(
          catchError((error) => {
              return throwError(error);
          })
        );
  }

  getCustomerLogo(): Observable<CustomerLogoResponse> {
    
    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_LOGO + '/controller')
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveCustomerLogo(logoInfo: CustomerLogoRequest) {
    
    return this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_LOGO, logoInfo)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  setLogoSrc(url: string) {
    this.logoSrc.next(url);
  }

  getSettingsEmail(): Observable<EmailSettings> {
    
    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_EMAIL)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveSettingsEmail(info: EmailSettings) {

    return this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_EMAIL, info)
        .pipe(
          catchError((error) => {
              return throwError(error);
          })
        );
  }

  getSettingsTextSms(): Observable<TextSmsSettings> {
    
    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_SMS)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveSettingsTextSms(info: TextSmsSettings) {

    let result: any;
  
    if (info.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_SMS, info)
                  .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                  )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_SMS, info)
                  .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                  )
    }
    return result;   
  }

  /* Custom Fields */
  
  getCustomFieldsTableDataConf() {
    return [
      { prop: 'name', name: 'NAME'},
      { prop: 'title', name: 'TITLE'},
      { prop: 'fieldType', name: 'FIELD_TYPE'},
      { prop: 'application', name: 'APPLICATION'},
      { prop: 'isActive', name: 'IS_ACTIVE'},
      { prop: 'isCore', name: 'IS_CORE'},
      { prop: 'actions', name: 'ACTIONS'}
    ];
  }
  
  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<CustomField>
  */
  getCustomFields(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {
  
    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g,'') : search.value));
    params = params
        .set(this.paramHelper.getSortParamName(false), sort)
        .set(this.paramHelper.getSortOrderParamName(false), this.paramHelper.getSortOrderParamValue(order))
        .set(this.paramHelper.getPageParamName(false), "" + page)
        .set(this.paramHelper.getPerPageParamName(false), "" + per_page);
  
    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }
  
    return this.http.get<any>(this.environmentForDomain.mockWebApiBaseUrl + BASE_URL_CUSTOM_FIELDS, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  
  getActiveCustomFields(): Observable<CustomField[]> {
  
    let params = new HttpParams();
    params = params
        .set("q", "true")
        .set(this.paramHelper.getSearchFieldsParamName(), "IsActive")
        .set(this.paramHelper.getSortParamName(false), "Name")
        .set(this.paramHelper.getSortOrderParamName(false), this.paramHelper.getSortOrderParamValue("asc"))
        .set(this.paramHelper.getPageParamName(false), "1")
        .set(this.paramHelper.getPerPageParamName(false), "100");
  
    return this.http.get<any>(this.environmentForDomain.mockWebApiBaseUrl + BASE_URL_CUSTOM_FIELDS, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  
  getCustomFieldById(id: string): Observable<CustomField> {
  
    return this.http.get<any>(this.environmentForDomain.mockWebApiBaseUrl + BASE_URL_CUSTOM_FIELDS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  
  saveCustomField(customFieldInfo: CustomField) {
    
    let result: any;
  
    if (customFieldInfo.id) {
      result = this.http.put(this.environmentForDomain.mockWebApiBaseUrl + BASE_URL_CUSTOM_FIELDS + '/' + customFieldInfo.id, customFieldInfo)
                  .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                  )
    } else {
      customFieldInfo.id = "21e9ca32-82b5-1234-c7cb-08d89ab25b" +  Math.floor((Math.random() * 100) + 10);
      result = this.http.post(this.environmentForDomain.mockWebApiBaseUrl + BASE_URL_CUSTOM_FIELDS, customFieldInfo)
                  .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                  )
    }
    return result;   
  }
  
  deleteCustomField(id: string) {
    return this.http.delete(this.environmentForDomain.mockWebApiBaseUrl + BASE_URL_CUSTOM_FIELDS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /* Translations */
  
  getTranslationsTableDataConf() {
    return [
      { prop: 'langKey', name: 'LANG_KEY'},
      { prop: 'langValue', name: 'LANG_VALUE'},
      { prop: 'actions', name: 'ACTIONS'}
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<Translation>
  */
 getTranslations(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {
  
  let params = new HttpParams();
  query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g,'') : search.value));
  params = params
      .set(this.paramHelper.getSortParamName(false), sort)
      .set(this.paramHelper.getSortOrderParamName(false), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(false), "" + page)
      .set(this.paramHelper.getPerPageParamName(false), "" + per_page);

  return this.http.get<any>(this.environmentForDomain.mockWebApiBaseUrl + BASE_URL_TRANSLATIONS, {
    params: params,
    observe: 'response'
  }).pipe(
    catchError((error) => {
      return throwError(error);
    })
  );
}
  
  saveTranslation(translationInfo: Translation) {
    
    return this.http.put(this.environmentForDomain.mockWebApiBaseUrl + BASE_URL_TRANSLATIONS + '/' + translationInfo.id, translationInfo)
            .pipe(
              catchError((error) => {
                  return throwError(error);
              })
            )   
  }

}
