import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig } from '@angular/material/core';
import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { HttpAuthenticationInterception } from 'app/shared/interceptors/http-authentication-interceptor.service';
//import { TokenInterceptor } from './shared/interceptors/token.interceptor';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AuthenticationModule } from './auth/authentication.module';
import { AuthGuard } from './shared/guards/auth.guard';

import { ToastrModule } from 'ngx-toastr';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCustom } from './shared/services/intl/mat-paginator-intl.service';
import { MatSortHeaderIntl } from '@angular/material/sort';
import { MatSortHeaderIntlCustom } from './shared/services/intl/mat-sort-header-intl.service';

import { NgxsModule } from '@ngxs/store';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgIdleModule } from '@ng-idle/core';

import { SettingsService } from 'app/shared/services/settings/settings.service';
import { OmniCloudToastrComponent } from './shared/components/toastr/omni-cloud-toastr.component';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true}),
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),

    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    AuthenticationModule,
    ToastrModule.forRoot({
      toastComponent: OmniCloudToastrComponent
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsModule.forRoot(),
    NgIdleModule.forRoot()
  ],
  declarations: [AppComponent,OmniCloudToastrComponent],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom},
    { provide: MatSortHeaderIntl, useClass: MatSortHeaderIntlCustom},
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthenticationInterception, multi: true },
    AuthGuard,
    SettingsService
  ],
  bootstrap: [AppComponent],
  entryComponents: [OmniCloudToastrComponent]
})
export class AppModule { }