<ng-container *ngIf="userData$ | async as userData">
    <div id="app-settings">
        <mat-card class="p-0">
            <mat-card-title class="m-0 light-gray">
                <div class="card-title-text" fxLayout="row wrap" fxLayoutAlign="center center">
                    <h3 class="m-0">{{ 'APP_SETTINGS.TITLE' | translate}}</h3>
            
                    <span fxFlex></span>
                    <button class="card-control" mat-icon-button (click)="closePanel()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </mat-card-title>
            <mat-card-content>
                <h3>
                    {{'APP_SETTINGS.USER_GREETING' | translate: {'value': currentUser.email} }}
                </h3>
                <button mat-raised-button style="margin-top: 10px;" (click)="onLogout()" attr.aria-label="{{ 'TOP_NAV.SIGNOUT' | translate}}">
                    <mat-icon>exit_to_app</mat-icon>
                    <span >{{ 'TOP_NAV.SIGNOUT' | translate}}</span>
                </button>
            </mat-card-content>
        </mat-card>
        <mat-card class="p-0">
            <mat-card-content *ngIf="isLoading === false">

                <div class="pb-1 mb-1 border-bottom">
                    <!-- <h6 class="title text-muted">{{ "APP_SETTINGS.THEMES" | translate }}</h6>
                    <div class="colors">
                        <div class="color" role="button" [(colorPicker)]="selectedThemeColor" [style.background]="selectedThemeColor"
                        [cpAlphaChannel]="'disabled'" [cpPosition]="'bottom-right'" [cpOKButton]="true" (colorPickerSelect)="changeDefaultTheme(selectedThemeColor, 'cardex-custom')"
                        [cpCancelButton]="true" [cpOKButtonClass]="'mat-raised-button'" [cpCancelButtonClass]="'mat-raised-button'" [cpSaveClickOutside]="false" [cpPresetColors]="presetColors">
                            <mat-icon class="active-icon" *ngIf="selectedTheme == 'cardex-custom'">check</mat-icon>
                        </div>
                    </div> -->
                    <mat-slide-toggle
                        color="primary"
                        name="darkTheme"
                        [checked]="isDarkTheme"
                        (change)="isDarkTheme = $event.checked">
                        Dark Theme
                    </mat-slide-toggle>
                </div>
            
                <div class="pb-1 mb-1 border-bottom">
                    <mat-form-field class="full-width">
                        <mat-label>{{ "APP_SETTINGS.LANGUAGE" | translate }}</mat-label>
                        <mat-select [(value)]="selectedLang" attr.aria-label="{{ 'APP_SETTINGS.LANGUAGE_SELECTED' | translate }}">
                            <mat-option [value]="lang" *ngFor="let lang of languages" (click)="changeLanguage(lang)" (keydown.enter)="changeLanguage(lang)" 
                            attr.aria-label="{{ 'LANGUAGES.' + lang | uppercase | translate }}">
                                {{ "LANGUAGES." + lang | uppercase | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            
                <div class="pb-1 mb-1 border-bottom">
                        <mat-slide-toggle
                            color="primary"
                            name="isAutoTZ"
                            [checked]="isAutoTZ"
                            (change)="isAutoTZ = $event.checked">
                            Auto Timezone
                        </mat-slide-toggle>
                    <mat-form-field class="full-width" *ngIf="isAutoTZ === false">
                        <mat-label>{{ "APP_SETTINGS.TIMEZONE" | translate }}</mat-label>
                        <mat-select [(value)]="selectedTZ" 
                        attr.aria-label="{{ 'APP_SETTINGS.TIMEZONE_SELECTED' | translate:{'value': selectedTZ, 'offset': selectedTZOffset} }}">
                            <mat-option [value]="tz.value" *ngFor="let tz of timezones" (click)="changeTimezone(tz.value, tz.offset)"
                            (keydown.enter)="changeTimezone(tz.value, tz.offset)" attr.aria-label="{{ tz.value + ' - ' + tz.offset }}">
                                {{ tz.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            
                <div class="pb-1 mb-1 border-bottom">
                    <mat-form-field class="full-width">
                        <mat-label>{{ "APP_SETTINGS.DATE_FORMAT" | translate }}</mat-label>
                        <mat-select [(value)]="selectedDateFormat" attr.aria-label="{{ 'APP_SETTINGS.DATE_FORMAT_SELECTED' | translate:{'value': selectedDateFormat} }}">
                            <mat-option [value]="dateFormat.format" *ngFor="let dateFormat of dateFormats" (click)="changeDateFormat(dateFormat.format)" 
                            (keydown.enter)="changeDateFormat(dateFormat.format)" attr.aria-label="{{ dateFormat.format }}">
                                {{ dateFormat.text }} ({{today | date:dateFormat.format}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            
                <div class="pb-1 mb-1 border-bottom">
                    <mat-form-field class="full-width">
                        <mat-label>{{ "APP_SETTINGS.TIME_FORMAT" | translate }}</mat-label>
                        <mat-select [(value)]="selectedTimeFormat" attr.aria-label="{{ 'APP_SETTINGS.TIME_FORMAT_SELECTED' | translate:{'value': selectedTimeFormat} }}">
                            <mat-option [value]="timeFormat.format" *ngFor="let timeFormat of timeFormats" (click)="changeTimeFormat(timeFormat.format)"
                            (keydown.enter)="changeTimeFormat(timeFormat.format)" attr.aria-label="{{ timeFormat.format }}">
                                {{ timeFormat.text }} ({{today | date:timeFormat.format}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="pb-1 mb-1 border-bottom">
                    <mat-form-field class="full-width">
                        <mat-label>{{ "APP_SETTINGS.IDLE_TIME" | translate }}</mat-label>
                        <mat-select [(value)]="selectedIdleTimeoutOption"
                            attr.aria-label="{{ 'APP_SETTINGS.IDLE_TIME_SELECTED' | translate:{'value': selectedIdleTimeoutOption / 60} }}">
                            <mat-option [value]="idleTimeoutOption.value" *ngFor="let idleTimeoutOption of idleTimeoutOptions"
                                (click)="changeIdleTimeoutOption(idleTimeoutOption.value)" (keydown.enter)="changeIdleTimeoutOption(idleTimeoutOption.value)"
                                attr.aria-label="{{ idleTimeoutOption.name }}">
                                {{ idleTimeoutOption.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="pb-1 mb-1 border-bottom">
                    <button 
                        mat-raised-button 
                        attr.aria-label="{{ 'APP_SETTINGS.CHANGE_PASSWORD' | translate }}"
                        (click)="onChangePassword(); $event.preventDefault()">{{ 'APP_SETTINGS.CHANGE_PASSWORD' | translate }}</button>
                </div>

                <div class="pb-1 mb-1 border-bottom">
                    <a mat-raised-button href="https://help.cardexchangesolutions.com/admincenter" target="_blank">
                        {{ 'APP_SETTINGS.NEED_HELP' | translate }}
                    </a>
                </div>

                <div class="control-buttons-wrapper">
                    <button 
                        mat-raised-button 
                        attr.aria-label="{{ 'FORM_CTRL.SAVE' | translate }}"
                        (click)="applyChanges()">{{ 'FORM_CTRL.SAVE' | translate }}</button>
        
                    <button 
                        mat-raised-button 
                        attr.aria-label="{{ 'FORM_CTRL.CANCEL' | translate }}"
                        (click)="closePanel(); $event.preventDefault()">{{ 'FORM_CTRL.CANCEL' | translate }}</button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</ng-container>